<template>
  <div>
    <associatedOption
      type="combobox"
      :fieldAttributes="{
        name: 'apn',
        associatedOption: result.additional.apns,
      }"
      v-on="$listeners"
      :value="result.valueLine.apn"
      :templateContent="result"
    >
    </associatedOption>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";

export default {
  components: {
    associatedOption,
  },
  props: ["result", "stepStoredValue"],
};
</script>